<template>
  <div>
    <top-banner></top-banner>
    <main class="main-auto">
      <my-breadcrumb></my-breadcrumb>
      <header-title title="客户案例" height="180px"></header-title>
      <div style="overflow:auto">
          <div class="content-f" v-html="htmlData"></div>
          <!-- <div
          v-if="img"
          class="img-box"
          :style="{ backgroundImage : 'url(' + img +')' }">
          </div> -->
      </div>
    </main>
  </div>
</template>

<script>
import MyBreadcrumb from "@/components/Breadcrumb.vue";
import TopBanner from "@/components/TopBanner.vue";
import headerTitle from "@/components/header_title";
import activity from "@/components/Activity_new";
export default {
  components: {
    activity,
    MyBreadcrumb,
    TopBanner,
    headerTitle,
  },
  data() {
    return {
      id: "",
      title: "",
      htmlData: "",
      img: "",
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    this.title = this.$route.query.title
    this.htmlData = this.$route.query.htmlData
    if(this.$route.query.img) {
      this.img = this.$route.query.img
    }
  },
};
</script>

<style lang="scss" scoped>
  .main-auto {
    margin: 0 auto;
  }
  .article{
    width: 842px;
    height: 455px;
    margin: 73px auto 0;
    background-color: beige;
  }
  .content-f {
    color: #333333;
    font-size: 24px;
    margin: 0 0 70px;
    padding: 0 70px;
  }
  .img-box {
    width: 842px;
    height: 455px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4px;
    margin: 72px auto;
  }
</style>